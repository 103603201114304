// @flow strict
import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import Author from "../components/Post/Author";
import Content from "../components/Post/Content";
import styles from "../components/Post/Post.module.scss";
import Gallery from "@browniebroke/gatsby-image-gallery";
import "@browniebroke/gatsby-image-gallery/dist/style.css";
import Layout from "../components/Layout";

const Photos = ({ data }) => {
    const fullSize = data.images.edges.map((edge) => edge.node.full.fluid.src);
    const thumbs = data.images.edges.map((edge) => edge.node.thumb.fluid);

    return (
        <Layout>
            <Link className={styles["post__home-button"]} to="/">
                Home
            </Link>
            <div className={styles["post__content"]}>
                <Content
                    body={
                        '<div>A few of my favorite moments I\'ve captured. On a side note, I use an image optimization here for performance that may serve a much lower resolution image. You can view all my photos in full resolution on my <a href="https://www.flickr.com/photos/170025184@N07/" target="_blank">flickr</a>.</div>'
                    }
                    title={"Photos"}
                />
            </div>
            <Gallery images={fullSize} thumbs={thumbs} />

            <div className={styles["post__footer"]}>
                <Author />
            </div>
        </Layout>
    );
};

export const query = graphql`
    query ImagesForGallery {
        images: allFile(
            filter: { relativeDirectory: { eq: "gallery" } }
            sort: { fields: name }
        ) {
            edges {
                node {
                    id
                    thumb: childImageSharp {
                        fluid(maxWidth: 270, maxHeight: 270) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    full: childImageSharp {
                        fluid(
                            maxWidth: 1024
                            quality: 85
                            srcSetBreakpoints: [576, 768, 992, 1200]
                        ) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

export default Photos;
